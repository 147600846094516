import { IBrandCartV2 } from '@faire/web-api--source/faire/carts/IBrandCartV2';
import { IEoriCheckoutContent } from '@faire/web-api--source/faire/carts/IEoriCheckoutContent';
import { IFilterSection } from '@faire/web-api--source/indigofair/data/IFilterSection';

export namespace ICartV2 {
  export namespace ICartStats {
    /**
     * Return an instance of ICartStats with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (partial?: Partial<ICartStats>): ICartStats =>
      ({
        ...(partial ?? {}),
      }) as ICartStats;
  }

  export interface ICartStats {
    active_brand_count: number | undefined;
    saved_for_later_brand_count: number | undefined;
    auto_moved_to_saved_for_later_brand_count: number | undefined;
  }

  /**
   * Return an instance of ICartV2 with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<ICartV2>): ICartV2 =>
    ({
      brand_carts: [],
      filter_sections: [],
      ...(partial ?? {}),
    }) as ICartV2;
}

export interface ICartV2 {
  /**
   * @protobufOption (.faire.common.tokenType) = CART
   */
  token: string | undefined;
  /**
   * @protobufOption (faire.common.diffAs) = UNORDERED
   * @protobufOption (faire.common.uniqueKey) = "brandToken"
   */
  brand_carts: Array<IBrandCartV2>;
  filter_sections: Array<IFilterSection>;
  cart_stats: ICartV2.ICartStats | undefined;
  /**
   * (FD-229056): Deprecated, waiting for old mobile releases to be removed before deletion
   * @deprecated
   */
  eori_checkout_content: IEoriCheckoutContent | undefined;
  cursor: string | undefined;
}
