import { IF_DEBUG } from "@faire/web--source/common/consts/DEBUG";
import { stringToGibberish } from "@faire/web--source/common/localization/gibberish";
import { LocalizationStore } from "@faire/web--source/common/localization/LocalizationStore";
import { Storage } from "@faire/web--source/common/Storage";
import { IDayMonthYear } from "@faire/web-api--source/indigofair/data/IDayMonthYear";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { ITimestamp } from "@faire/web-api--source/indigofair/rfc3339/ITimestamp";
import { getDay } from "date-fns/getDay";
import { getMonth } from "date-fns/getMonth";
import { getYear } from "date-fns/getYear";

import { DateLocaleStore } from "./DateLocaleStore";
import { Locale } from "date-fns";

export const getUserLocale = (): Locale => {
  // TODO: (Bo, next week): update date-fns package to align with all repo and check if default still a thing.
  const dateLocale = DateLocaleStore.get().dateLocale;
  if (Object.keys(dateLocale).includes("default")) {
    return (dateLocale as any).default;
  }
  if (Storage.local.getItem(IF_DEBUG)) {
    // eslint-disable-next-line no-console
    console.log("dateLocale", dateLocale);
  }
  return dateLocale;
};

/**
 * Helper function that replaces the string by gibberish if Gibberish Mode
 * is enabled
 */
export const maybeConvertToGibberish = (str: string): string =>
  LocalizationStore.get().localeKey?.language === Language.GIBBERISH
    ? stringToGibberish(str)
    : str;

/**
 * Helper function for narrowing a value from (ITimestamp | Date | number)
 * to (Date | number)
 */
export const getDateOrMillis = (
  date: IDayMonthYear | ITimestamp | Date | number
): number | Date => {
  if (typeof date === "number") {
    return date;
  }
  if (instanceOfDayMonthYear(date)) {
    const now = new Date();
    return new Date(
      date.year ?? getYear(now),
      date.month ? date.month - 1 : getMonth(now),
      date.day ?? getDay(now)
    );
  }
  if (instanceOfITimestamp(date)) {
    // 1 second = 10^3 milleseconds
    // 1 millesecond = 10^6 nanoseconds
    return (date.seconds ?? 0) * 1000 + Math.floor((date.nanos ?? 0) / 1000000);
  }

  // type of `date` should be Date if we get to this point
  return date as Date;
};

const instanceOfDayMonthYear = (
  date: IDayMonthYear | ITimestamp | Date
): date is IDayMonthYear =>
  !!date && ("year" in date || "month" in date || "day" in date);

const instanceOfITimestamp = (date: ITimestamp | Date): date is ITimestamp =>
  !!date && ("nanos" in date || "seconds" in date);

const USER_TIMEZONE_OFFSET_IN_MILLIS = new Date().getTimezoneOffset() * 60000;

export const convertUTCToLocal = (UTCDate: number) => {
  return UTCDate - USER_TIMEZONE_OFFSET_IN_MILLIS;
};

export const convertLocalToUTC = (localDate: number) => {
  return localDate + USER_TIMEZONE_OFFSET_IN_MILLIS;
};
