import { useNationality } from "@faire/retailer-visitor-shared/hooks/retailer/useNationality";
import { useRetailer } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailer";
import { isRetailerCreatedOver30DaysOld } from "@faire/retailer-visitor-shared/lib/isRetailerCreatedOver30DaysOld";
import { flashSaleSeasonalWarehouseSaleIsOn } from "@faire/retailer-visitor-shared/settings/getSettingSeasonalWarehouseSale";
import { useSettingMigrateRetailerStore } from "@faire/retailer-visitor-shared/settings/useSettingMigrateRetailerStore";
import { useRetailerStore } from "@faire/retailer-visitor-shared/stores/domain/RetailerStore";
import { useUserStore } from "@faire/retailer-visitor-shared/stores/domain/UserStore";
import { useMemo } from "react";

const useFlashSaleControl = () => {
  const {
    showFlashSales: showFlashSalesControl,
    showFlashSalesAsUnavailable: showFlashSalesAsUnavailableControl,
    showWarehouseFlashSale: showWarehouseFlashSaleControl,
  } = useRetailerStore([
    "showFlashSales",
    "showFlashSalesAsUnavailable",
    "showWarehouseFlashSale",
  ]);

  return {
    showFlashSales: showFlashSalesControl,
    showFlashSalesAsUnavailable: showFlashSalesAsUnavailableControl,
    showWarehouseFlashSale: showWarehouseFlashSaleControl,
  };
};

const useFlashSaleTreatment = () => {
  const { retailer } = useRetailer();
  const { isAmerican, retailerCountry } = useNationality();
  const { userCanPlaceOrder } = useUserStore(["userCanPlaceOrder"]);

  const showFlashSales = useMemo(() => {
    if (!userCanPlaceOrder) {
      return false;
    }

    return (
      !!retailer &&
      !retailer.hide_redistribution_channels &&
      (isAmerican || retailerCountry === undefined) &&
      isRetailerCreatedOver30DaysOld(retailer.created_at)
    );
  }, [userCanPlaceOrder, retailer, isAmerican, retailerCountry]);

  /**
   * Whether to couple the fact that we don't show FS/M&M (see above)
   * with a different UI that still exposes entry-points and a sorry message.
   */
  const showFlashSalesAsUnavailable = useMemo(() => {
    if (!userCanPlaceOrder) {
      return false;
    }

    return (
      !!retailer?.hide_redistribution_channels &&
      !!retailer?.show_flash_sales_mix_and_match_as_unavailable &&
      (isAmerican || retailerCountry === undefined)
    );
  }, [userCanPlaceOrder, retailer, isAmerican, retailerCountry]);

  const showWarehouseFlashSale =
    showFlashSales && flashSaleSeasonalWarehouseSaleIsOn();

  return {
    showFlashSales,
    showFlashSalesAsUnavailable,
    showWarehouseFlashSale,
  };
};

export const useFlashSale = () => {
  const shouldUseHooksMigration = useSettingMigrateRetailerStore();
  if (shouldUseHooksMigration) {
    // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
    return useFlashSaleTreatment();
  }
  // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
  return useFlashSaleControl();
};
