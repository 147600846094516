import { BrandPageQueryObj } from "@faire/retailer-shared/search/BrandPageQueryObj";
import { getIsBrandPreview } from "@faire/retailer-visitor-shared/lib/getIsBrandPreview";
import {
  pathMatchesRoute,
  RouteMatcher,
} from "@faire/web--source/common/routes/RouteMatcher";
import { isPrerender } from "@faire/web--source/common/user-agent/isPrerender";
import brandPortal from "@faire/web-api--source/routes/www/brand-portal";
import { path as brandPageFromRoutes } from "@faire/web-api--source/routes/www/brand/brandToken";
import { route as c1Route } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId";
import { route as c1BrandValueRoute } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId/brand-value/brandValue";
import { route as c2Route } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId/subcategory/c2CategoryUrlId";
import { route as c2BrandValueRoute } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/brand-value/brandValue";
import { route as c3Route } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId";
import { route as c3BrandValueRoute } from "@faire/web-api--source/routes/www/category/c1CategoryUrlId/subcategory/c2CategoryUrlId/c3CategoryUrlId/brand-value/brandValue";
import { route as discoverRoute } from "@faire/web-api--source/routes/www/discover/searchTerm";
import homepage from "@faire/web-api--source/routes/www/root";
import qs from "query-string";

const buildQueryString = (queryObj?: Record<string, unknown>): string => {
  const queryString = queryObj ? qs.stringify(queryObj) : "";
  return queryString ? `?${queryString}` : "";
};

const buildHashFragmentString = (hashObj?: Record<string, unknown>): string => {
  const hashString = hashObj ? qs.stringify(hashObj) : "";
  return hashString ? `#${hashString}` : "";
};

const CATEGORY_ROUTES = [
  c1Route,
  c2Route,
  c3Route,
  c1BrandValueRoute,
  c2BrandValueRoute,
  c3BrandValueRoute,
];

export const isFromCategoryURL = (link: string) => {
  const isCategoryRoute = new RouteMatcher(CATEGORY_ROUTES);
  return isCategoryRoute.match(link);
};

export const isFromDiscoverURL = (link: string) =>
  pathMatchesRoute(link, discoverRoute);

/**
 * Redirect to the brand portal (if user is a brand), or to / otherwise.
 */
export const home: typeof homepage = (query): string => {
  if (getIsBrandPreview()) {
    return brandPortal();
  }
  return homepage(query);
};

export const brandPage = (
  brandToken: string,
  queryObj?: BrandPageQueryObj,
  hashObj?: Record<string, string | undefined>,
  useJoinFilters?: boolean
): string => {
  if (isPrerender()) {
    // For SEO discoverability, we want to render brand tile urls without any
    // query parameters so that google bot can see the canonical path to brand page
    return brandPageFromRoutes(brandToken);
  }

  const queryString = useJoinFilters
    ? brandPageQueryStringJoinFilters(queryObj)
    : brandPageQueryString(queryObj);
  const hashString = buildHashFragmentString(hashObj);
  return `/brand/${brandToken}${queryString}${hashString}`;
};

function brandPageQueryString(queryObj?: BrandPageQueryObj): string {
  if (queryObj && queryObj.page && queryObj.page < 2) {
    queryObj.page = undefined;
  }

  if (typeof queryObj?.refF === "string") {
    queryObj.refF = [queryObj.refF];
  }

  return buildQueryString(queryObj);
}

function brandPageQueryStringJoinFilters(queryObj?: BrandPageQueryObj): string {
  if (queryObj && queryObj.page && queryObj.page < 2) {
    queryObj.page = undefined;
  }

  if (typeof queryObj?.refF === "string") {
    queryObj.refF = [queryObj.refF];
  }

  const queryString = queryObj
    ? qs.stringify({
        ...queryObj,
        filters: queryObj?.filters?.join(",") || undefined,
      })
    : "";

  return queryString ? `?${queryString}` : "";
}

const blogLocale = (locale: string): string => {
  if (locale.startsWith("de")) {
    return "/de";
  }
  if (locale.startsWith("fr")) {
    return "/fr";
  }
  if (locale === "en-GB") {
    return "/en-gb";
  }
  return "";
};

export const blogPage = (locale: string, category?: string): string => {
  const url = `https://www.faire.com${blogLocale(locale)}/blog`;
  return category ? `${url}/${category}` : url;
};
