import { useRetailer } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailer";
import { getSessionCountry } from "@faire/retailer-visitor-shared/serialized-data/getSessionCountry";
import { useSettingMigrateRetailerStore } from "@faire/retailer-visitor-shared/settings/useSettingMigrateRetailerStore";
import { useRetailerStore } from "@faire/retailer-visitor-shared/stores/domain/RetailerStore";
import { useUserStore } from "@faire/retailer-visitor-shared/stores/domain/UserStore";
import { THREE_LETTER_EUROPE_SUPPORTED_COUNTRIES } from "@faire/web--source/common/consts/THREE_LETTER_EUROPE_SUPPORTED_COUNTRIES";
import { THREE_LETTER_PRIMARY_COUNTRY_CODES } from "@faire/web--source/common/consts/THREE_LETTER_PRIMARY_COUNTRY_CODES";
import { CurrencyUnit } from "@faire/web-api--source/faire/currency/CurrencyUnit";
import { ISO3166Alpha3 } from "@faire/web-api--source/indigofair/iso3166/ISO3166Alpha3";

const useNationalityControl = () => {
  const {
    retailerCountry,
    isAmerican,
    isCanadian,
    isUK,
    isAustralian,
    isEurope,
    isEuropeNonUk,
    isNewOrMigratedCanadian,
    isInternational,
    geographicState,
  } = useRetailerStore([
    "retailerCountry",
    "isAmerican",
    "isCanadian",
    "isUK",
    "isAustralian",
    "isEurope",
    "isEuropeNonUk",
    "isNewOrMigratedCanadian",
    "isInternational",
    "geographicState",
  ]);

  return {
    retailerCountry,
    isAmerican,
    isCanadian,
    isUK,
    isAustralian,
    isEurope,
    isEuropeNonUk,
    isNewOrMigratedCanadian,
    isInternational,
    geographicState,
  };
};

const useNationalityTreatment = () => {
  const { defaultAddress } = useUserStore(["defaultAddress"]);
  const { retailer } = useRetailer();

  const retailerCountry =
    (retailer?.country as ISO3166Alpha3 | undefined) || getSessionCountry();
  const isAmerican = retailerCountry === THREE_LETTER_PRIMARY_COUNTRY_CODES.USA;
  const isCanadian = retailerCountry === THREE_LETTER_PRIMARY_COUNTRY_CODES.CAN;
  const isUK = retailerCountry === THREE_LETTER_PRIMARY_COUNTRY_CODES.GBR;
  const isAustralian =
    retailerCountry === THREE_LETTER_PRIMARY_COUNTRY_CODES.AUS;
  const isEurope = THREE_LETTER_EUROPE_SUPPORTED_COUNTRIES.includes(
    retailerCountry as (typeof THREE_LETTER_EUROPE_SUPPORTED_COUNTRIES)[number]
  );
  const isEuropeNonUk = isEurope && !isUK;
  const isNewOrMigratedCanadian =
    isCanadian && retailer?.currency === CurrencyUnit.CAD;
  const isInternational = !isAmerican && !isCanadian;
  const geographicState = retailer?.address?.state ?? defaultAddress?.state;

  return {
    retailerCountry,
    isAmerican,
    isCanadian,
    isUK,
    isAustralian,
    isEurope,
    isEuropeNonUk,
    isNewOrMigratedCanadian,
    isInternational,
    geographicState,
  };
};

export const useNationality = () => {
  const shouldUseHooksMigration = useSettingMigrateRetailerStore();
  if (shouldUseHooksMigration) {
    // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
    return useNationalityTreatment();
  }
  // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
  return useNationalityControl();
};
