import { DEFAULT_LOCALES } from "@faire/web--source/common/consts/DEFAULT_LOCALES";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import { da } from "date-fns/locale/da";
import { de } from "date-fns/locale/de";
import { enUS } from "date-fns/locale/en-US";
import { es } from "date-fns/locale/es";
import { fr } from "date-fns/locale/fr";
import { it } from "date-fns/locale/it";
import { nl } from "date-fns/locale/nl";
import { pt } from "date-fns/locale/pt";
import { sv } from "date-fns/locale/sv";

import { LocalizationStore } from "../LocalizationStore";
import { Locale } from "date-fns";

/**
 * @deprecated use `useDateLocaleStore` or `withStores` instead
 */
export class DateLocaleStore {
  static get = singletonGetter(DateLocaleStore);

  private _dateLocale!: Locale;

  constructor() {
    this.init();
  }

  init = () => {
    const locale = LocalizationStore.get().locale;
    const simplifiedLocale = locale.split("-")[0] ?? "";
    this._dateLocale =
      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      DATE_LOCALES[locale] ||
      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      DATE_LOCALES[simplifiedLocale] ||
      DATE_LOCALES["en"];
  };

  get dateLocale() {
    return this._dateLocale;
  }
}
const gibberishDateLocal: Locale = {
  ...enUS,
  code: DEFAULT_LOCALES[Language.GIBBERISH],
};

const DATE_LOCALES = {
  "en-US": enUS,
  en: enUS,
  "fr-FR": fr,
  fr: fr,
  "de-DE": de,
  de: de,
  "it-IT": it,
  it: it,
  "es-ES": es,
  es: es,
  "sv-SE": sv,
  sv: sv,
  "pt-PT": pt,
  pt: pt,
  "da-DK": da,
  da: da,
  "nl-NL": nl,
  nl: nl,
  xa: gibberishDateLocal,
};
