import { request } from '@faire/web-api--source';
import { BrandCartsSortBy } from '@faire/web-api--source/faire/carts/BrandCartsSortBy';
import { ICartV2 } from '@faire/web-api--source/faire/carts/ICartV2';
import { IShippingMethod } from '@faire/web-api--source/indigofair/data/IShippingMethod';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';
import qs from 'query-string';

export type QueryParameters = {
  brand_tokens: Array<string> | undefined;
  sort_brand_carts_by: keyof typeof BrandCartsSortBy | undefined;
  shipping_method_type:
    | keyof typeof IShippingMethod.ShippingMethodType
    | undefined;
  exclude_alcohol_disclaimer: boolean | undefined;
  filter_brand_carts_by_keys: Array<string> | undefined;
  saved_for_later: boolean | undefined;
  should_collapse_preorder_groups: boolean | undefined;
  cursor: string | undefined;
  max_results: number | undefined;
  query: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;
}

export const path = (cartToken: string, queryParams?: QueryParameters) => {
  const queryString = queryParams ? `?${qs.stringify(queryParams)}` : '';
  return `/api/v2/carts/${cartToken}${queryString}`;
};

export const build = (
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(cartToken),
    method: 'GET',
    params: queryParams,
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/carts/:cartToken';

/**
 * Product Area: CART
 */
function execute(
  cartToken: string,
  queryParams?: QueryParameters
): Promise<ICartV2>;
function execute(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ICartV2>>;
function execute(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ICartV2>;
function execute(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ICartV2 | SuccessResponse<ICartV2>> {
  const { route, ...requestOptions } = build(cartToken, queryParams, options);
  return request<ICartV2>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/v2/carts/cartToken/get';
export default execute;

/**
 * Execute the request, as a dark read.
 * @see execute
 */
export function darkRead(
  cartToken: string,
  queryParams?: QueryParameters
): Promise<ICartV2>;
export function darkRead(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ICartV2>>;
export function darkRead(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ICartV2>;
export function darkRead(
  cartToken: string,
  queryParams?: QueryParameters,
  options?: EndpointOptions
): Promise<ICartV2 | SuccessResponse<ICartV2>> {
  const { route, ...requestOptions } = build(cartToken, queryParams, options);
  return request<ICartV2>(requestOptions);
}
