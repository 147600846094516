export const isRetailerCreatedOver30DaysOld = (
  createdAt: number | undefined
): boolean => {
  if (!createdAt) {
    return false;
  }

  const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000; // 30 days, converted into milliseconds
  const currentTime = Date.now(); // Current time in milliseconds
  return currentTime - createdAt > thirtyDaysInMillis;
};
