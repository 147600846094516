import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';

export type QueryParameters = {
  tab: string | undefined;
  query: string | undefined;
  filters: string | undefined;
  page: string | undefined;
  action: string | undefined;
  brandOrderToken: string | undefined;
  isCancellationRequest: string | undefined;
  reason: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/user/account/orders"
 *
 * Product Area: USERS
 */
export const route = '/user/account/orders';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/user/account/orders',
  productArea: 'USERS',
  pod: 'FAIRE_POD_SECURITY',
  pillar: 'FAIRE_PILLAR_EPDD_FOUNDATIONS',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

export function path(queryParams?: Partial<QueryParameters>) {
  const compiledPath = toPath({});
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (queryParams?: Partial<QueryParameters>): string => {
  return faireBaseUrl(3000) + path(queryParams);
};

export default path;
